<template>
  <div>
    <a-card :bordered="false" :tab-list="tabList" :active-tab-key="activeTabKey" @tabChange="onTabChange">
      <overview-pane v-if="activeTabKey == 'overview'" />
      <ranking-pane v-else-if="activeTabKey == 'ranking'" />
      <effect-pane v-else-if="activeTabKey == 'effect'" />
    </a-card>
  </div>
</template>

<script>
  export default {
    name: 'GoodsOverview',
    components: {
      OverviewPane: () => import('./overviewPane'),
      RankingPane: () => import('./rankingPane'),
      EffectPane: () => import('./effectPane'),
    },
    data() {
      return {
        activeTabKey: 'overview',
        tabList: [
          { key: 'overview', tab: '商品概况' },
          { key: 'ranking', tab: '商品排行' },
          { key: 'effect', tab: '商品效果' },
        ],
      };
    },
    methods: {
      onTabChange(key) {
        this.activeTabKey = key;
      },
    },
  }
</script>

<style scoped>
</style>